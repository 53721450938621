import { type Property } from '@ev/search-modules-api';
import { type SearchModuleFilters } from '@ev/search-modules-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { type CommonPageProps } from 'pages/_app';
import type { ReactElement } from 'react';

import { Footer } from '@/components/common/Footer/Footer';
import { type HeaderProps, Header } from '@/components/common/Header/Header';

const HeaderBlokDynamic = dynamic(() =>
  import('@/blocks').then((mod) => mod.HeaderBlock)
);

const FooterBlokDynamic = dynamic(() =>
  import('@/blocks').then((mod) => mod.FooterBlock)
);

type Props = HeaderProps & {
  children: React.ReactNode;
  stickyHeader?: boolean;
  withContactButton?: boolean;
  property?: Property;
  filters?: SearchModuleFilters;
  isPropertySearchPage?: boolean;
};

export const Skeleton = ({
  children,
  spearHeadNavigation,
  onLanguageChange,
  pageLanguages,
  stickyHeader,
  withContactButton,
  externalLinkDomains,
  property,
  filters,
  isPropertySearchPage,
}: Props & CommonPageProps): ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();
  const router = useRouter();

  const evLogoUrl = `https://www.engelvoelkers.com/${router.query.countryCodeOrLocale}/${language}/`;

  const ConditionalStickyWrapper = ({
    children,
  }: {
    children: React.ReactNode;
  }) =>
    isPropertySearchPage && spearHeadNavigation?.headerData ? (
      <div style={{ position: 'sticky', top: 0, zIndex: 3 }}>{children}</div>
    ) : (
      children
    );

  return (
    <>
      {spearHeadNavigation?.headerData ? (
        <ConditionalStickyWrapper>
          <HeaderBlokDynamic
            blok={spearHeadNavigation.headerData}
            className="sp-header"
            baseSlug={evLogoUrl}
            pageLanguages={pageLanguages}
            onLanguageChange={onLanguageChange}
            sticky={stickyHeader}
            withContactButton={withContactButton}
            externalLinkDomains={externalLinkDomains}
            property={property}
            filters={filters}
          />
        </ConditionalStickyWrapper>
      ) : (
        <Header onLanguageChange={onLanguageChange} />
      )}
      {children}
      {spearHeadNavigation?.footerData ? (
        <FooterBlokDynamic
          blok={spearHeadNavigation.footerData}
          externalLinkDomains={externalLinkDomains}
        />
      ) : (
        <Footer />
      )}
    </>
  );
};
